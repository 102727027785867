import React from 'react'
import {Link} from 'gatsby'
import { IoIosClose } from 'react-icons/io'

const Sidebar = ({toggleSidebar, isOpen}) => {
  return (
    <div className={`sidebar ${isOpen ? 'is-active' : ''}`}>
      <a aria-label={`Close navigation menu`} className={`sidebar-close`} onClick={toggleSidebar}><IoIosClose size='3em' /></a>
      <aside className='menu'>
        <p className='menu-label'>General</p>
        <ul className='menu-list'>
          <li><Link aria-label={`Navigate to the Home page`} className='navbar-item' to='/' onClick={toggleSidebar}>Home</Link></li>
          <li><Link aria-label={`Navigate to the About page`} className='navbar-item' to='/about' onClick={toggleSidebar}>About</Link></li>
          <li><Link aria-label={`Navigate to the Examples page`} className='navbar-item ' to='/examples' onClick={toggleSidebar}>Examples</Link></li>
          <li><Link aria-label={`Navigate to the Blog page`} className='navbar-item ' to='/blog' onClick={toggleSidebar}>Blog</Link></li>
          <li><Link aria-label={`Navigate to the Contact page`} className='navbar-item' to='/contact' onClick={toggleSidebar}>Contact</Link></li>
        </ul>
        <p className='menu-label '>Solutions & Services</p>
        <ul className='menu-list '>
          <li><Link aria-label={`Navigate to the Virtual Tours page`} className='navbar-item' to='/virtualTours' onClick={toggleSidebar}>Virtual Tours</Link></li>
          <li><Link aria-label={`Navigate to the Marketing page`} className='navbar-item' to='/marketing' onClick={toggleSidebar}>Marketing</Link></li>
          <li><Link aria-label={`Navigate to the Websites page`} className='navbar-item' to='/websites' onClick={toggleSidebar}>Websites</Link></li>
          <li><Link aria-label={`Navigate to the App Development page`} className='navbar-item' to='/apps' onClick={toggleSidebar}>App Development</Link></li>
          {/* <li><Link aria-label={`Navigate to the Pricing page`} className='navbar-item' to='/pricing' onClick={toggleSidebar}>Pricing</Link></li> */}
        </ul>
      </aside>
    </div>
  )
}

export default Sidebar
