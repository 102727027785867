import React, { Component } from 'react'
import Helmet from 'react-helmet'
import Sticky from 'react-stickynode'
import NavBar from '../components/NavBar'
import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'

import '../assets/sass/styles.sass'
import config from '../../data/config'

class TemplateWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {isNavOpen: false}
    this.toggleSidebar = this.toggleSidebar.bind(this)
  }

  toggleSidebar () {
    this.setState({isNavOpen: !this.state.isNavOpen})
  }

  render () {
    if (this.props.pageContext.layout === 'story') {
      return (
        <div>
          {this.props.children}
        </div>

      )
    }

    return (
      <div>
        <Helmet>
          <title>{config.siteTitle}</title>
          <meta name='description' content={config.siteDescription} />
        </Helmet>

        <div className={`mainWrapper ${this.state.isNavOpen ? 'blur-on is-hidden-mobile' : ''}`}>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <NavBar headerProps={this.props} isOpen={this.state.isNavOpen} toggleSidebar={() => this.toggleSidebar()} />
          </Sticky>
          {this.props.children}
          <Footer />
        </div>

        <Sidebar isOpen={this.state.isNavOpen} toggleSidebar={() => this.toggleSidebar()} />

      </div>
    )
  }
}

export default TemplateWrapper
