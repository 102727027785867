import React from 'react'
import {graphql, StaticQuery} from 'gatsby'
import SearchBox from '../SearchBox'
import logoFullColor from '../../assets/img/full_logo.png'
import logoWhite from '../../assets/img/logo_white.png'
import config from '../../../data/config'

const NavBar = ({toggleSidebar, isOpen}) => {
  return (
    <StaticQuery
      query={graphql`
              query SearchIndexQuery {
                  siteSearchIndex {
                      index
                  }
              }
          `}
      render={data => (

        <div>

          <nav className='navbar is-fixed-top is-transparent'>
            <div className='container'>
              <div className='navbar-brand sticky-not-active'>
                <p className='navbar-item' to='/'>
                  <img aria-label={`${config.siteTitle} Logo`} src={logoWhite} className='menu-icon-trigger' onClick={toggleSidebar} />
                </p>
              </div>
              <div className='navbar-brand sticky-active'>
                <p className='navbar-item' to='/'>
                  <img aria-label={`${config.siteTitle} Logo`} src={logoFullColor} className='menu-icon-trigger' onClick={toggleSidebar} />
                </p>
              </div>
              <div className='navbar-menu'>
                <div className='navbar-end'>
                  <div className='navbar-item'>
                    <SearchBox searchIndex={data.siteSearchIndex.index} />
                  </div>
                </div>
              </div>
              <div className='navbar-end'>
                <div className={`navbar-item menu-icon-wrapper ${isOpen ? 'open' : ''}`}>
                  <svg aria-label='site menu' width='1000px' height='1000px'>
                    <path className='path1' d='M 300 400 L 700 400 C 900 400 900 750 600 850 A 400 400 0 0 1 200 200 L 800 800' />
                    <path className='path2' d='M 300 500 L 700 500' />
                    <path className='path3' d='M 700 600 L 300 600 C 100 600 100 200 400 150 A 400 380 0 1 1 200 800 L 800 200' />
                  </svg>
                  <button role='button' aria-label={`Navigation`} id='menu-icon-trigger' className='menu-icon-trigger' onClick={toggleSidebar} />
                </div>
              </div>
            </div>
          </nav>

        </div>

      )}
    />
  )
}

export default NavBar
