module.exports = {
  siteTitle: 'Layer Cake Solutions', // Site title.
  siteshortTitle: 'One stop shop for digital content', // Site title.
  siteTitleAlt: 'Layer Cake', // Alternative site title for SEO.
  siteLogo: '/svg-icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://layercake.us', // https://layercake.us Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'Leverage Layer Cake for your Business.', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  googleTagManagerID: 'GTM-KS57375', // GTM tracking ID.
  disqusShortname: 'layercake-us', // Disqus shortname.
  userName: 'Justin Chambers',
  userTwitter: 'hellolayercake',
  userLocation: 'Elmhurst, Illinois',
  userDescription: '',
  companyEmail: 'support@layercake.us',
  copyright: 'Copyright © Layer Cake Solutions 2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#004d90', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  googleMapAPI: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCSYCwzmMpxUAXB3ptreTrV0jLpUdaNXew&v=3.exp&libraries=geometry,drawing,places',
  siteFacebookURL: 'https://www.facebook.com/layercakesolutions',
  siteTwitterURL: 'https://twitter.com/hellolayercake',
  siteLinkedinURL: 'https://www.linkedin.com/company/layercakesolutions/',
  siteYoutubeURL: 'https://www.youtube.com/channel/UCXNQU1EvXkqjCS3nlW10BGg',
  siteInstagramURL: 'https://www.instagram.com/layercakesolutions/',
  siteGithubURL: 'https://github.com/frozonice',
}
