import React from 'react'
import config from '../../../data/config'
import logo from '../../assets/img/full_logo.png'
import { FaSass, FaReact, FaFacebookF, FaMapMarkerAlt, FaPhone, FaEnvelope, FaTwitter, FaInstagram, FaGithubAlt, FaYoutube, FaLinkedin } from 'react-icons/fa'
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <footer className='footer-distributed edge--top'>
      <div className='container'>
        <div className='footer-left'>

          <img aria-label={`${config.siteTitle} logo`} src={logo} />

          <p className='footer-links' />

          <p className='footer-company-name'>{config.copyright}</p>

          <p className='footer-links'> 
            <Link aria-label={`View Privacy Statement for ${config.siteTitle}`} to='/privacy'>Privacy</Link> | <Link aria-label={`View Terms & Conditions Statement for ${config.siteTitle}`} to='/terms'>Terms & Conditions</Link>
          </p>
        </div>

        <div className='footer-center'>

          <div>
            <FaMapMarkerAlt />
            <p><strong>Elmhurst</strong>, Illinois, USA</p>
          </div>
          <div>
            <FaMapMarkerAlt />
            <p><strong>Culver</strong>, Indiana, USA</p>
          </div>

          <div>
            <FaPhone />
            <p>+1 312 607 6345</p>
          </div>

          <div>
            <FaEnvelope />
            <p><a aria-label={`Send an Email to ${config.companyEmail}`} href={`mailto:${config.companyEmail}`}>{config.companyEmail}</a></p>
          </div>

        </div>

        <div className='footer-right'>

          <p className='footer-company-about'>
            <span>About Layer Cake</span>
            Layer Cake provides a one stop shop for your digital content.
            From 3D scanning, aerial, Google street view to website creation and marketing.
            We use the latest in reality capture systems, and web technology that gives you realistic, interactive 3D and VR experiences that feel as real as being there.
          </p>

          <div className='footer-icons'>

            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Facebook page`} href={config.siteFacebookURL} target={`_blank`}><FaFacebookF /></a>
            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Twitter page`} href={config.siteTwitterURL} target={`_blank`}><FaTwitter /></a>
            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Instagram page`} href={config.siteInstagramURL} target={`_blank`}><FaInstagram /></a>
            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Github page`} href={config.siteGithubURL} target={`_blank`}><FaGithubAlt /></a>
            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Youtube page`} href={config.siteYoutubeURL} target={`_blank`}><FaYoutube /></a>
            <a rel='noopener' aria-label={`Go to ${config.siteTitle} Linkedin page`} href={config.siteLinkedinURL} target={`_blank`}><FaLinkedin /></a>

          </div>

        </div>
      </div>

    </footer>
  )
}

export default Footer
